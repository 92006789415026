import React from "react"

import Nav from "../components/nav"
import Content from "../components/content"
import StyledA from "../components/styleda"
import Card from "../components/card"

import google from "./img/google.png"
import googleBrain from "./img/google-brain.jpg"
import facebook from "./img/facebook.png"
import apollo from "./img/apollo-logo.jpg"
import meteor from "./img/meteor.jpg"
import khanacademy from "./img/khanacademy.png"
import coursera from "./img/coursera.jpg"
import payalabs from "./img/payalabs.jpg"
import { PageTitle } from "../components/titles"

const works = [
  {
    image: google,
    imageSize: "contain",
    name: "Google Search",
    year: 2021,
    position: "Software Engineering Intern",
    description: (
      <ul>
        <li>Intern on the Unicode ICU4X team, developing a cross platform Rust library for internationalization</li>
        <li>Built <StyledA href="https://github.com/rust-diplomat/diplomat">Diplomat</StyledA>, a system for automatically generating high-level bindings in from other languages to Rust APIs</li>
        <li>Developed support for compiling ICU4X to WebAssembly and using it through high-level JS APIs</li>
      </ul>
    ),
    url: "https://github.com/rust-diplomat/diplomat"
  },
  {
    image: googleBrain,
    name: "Google Brain",
    year: 2020,
    position: "Software Engineering Intern",
    description: (
      <ul>
        <li>Worked on the Swift for TensorFlow team, developing Swift as a first-class language for machine learning</li>
        <li>Designed new APIs for defining neural network model architectures that enables modularity and type-safety</li>
        <li>Developed new models for neural networks that align with Swift's value semantics and type-safety goals</li>
        <li>Presented updates on the project at Swift for TensorFlow SIG meetings</li>
      </ul>
    ),
    url: "https://github.com/tensorflow/swift/blob/master/proposals/modern-layer-api/LayerApiPrototypes.md"
  },
  {
    image: facebook,
    name: "Facebook",
    year: 2019,
    position: "Production Engineering Intern",
    description: (
      <ul>
        <li>Worked on the Ads Ranking PE team to develop machine learning infrastructure</li>
        <li>Developed a new stateless alerting system to monitor model training and inference</li>
        <li>Took on the primary oncall duty to fix issues blocking the online training of critical production models</li>
        <li>Implemented memory instruction testing in the fuzzer of a new AOT compiler written in Rust</li>
      </ul>
    )
  },
  {
    image: apollo,
    name: "Apollo GraphQL",
    year: 2018,
    position: "Software Engineering Intern",
    description: (
      <ul>
        <li>As a returning intern, I led a new initiative to develop command-line and editor integrations for GraphQL that are now in use at Airbnb and Netflix</li>
        <li>Communicated with the open-source GraphQL community as the primary maintainer of the <StyledA href="https://github.com/apollographql/apollo-cli">Apollo CLI</StyledA></li>
        <li>Created a  language server implementation and VS Code extension for GraphQL development</li>
      </ul>
    ),
    url: "https://twitter.com/apollographql/status/1017845325002768384"
  },
  {
    image: meteor,
    name: "Apollo GraphQL",
    year: 2017,
    position: "Software Engineering Intern",
    description: (
      <ul>
        <li>Worked on the open-source team to develop the Apollo GraphQL Client, used by companies such as Ticketmaster, Airbnb, and Coursera</li>
        <li>Developed a new Cache API to allow developers to plug in custom caching strategies into the client</li>
        <li>Wrote several <StyledA href="https://dev-blog.apollodata.com/tutorial-graphql-subscriptions-client-side-40e185e4be76">tutorials</StyledA> and <StyledA href="https://dev-blog.apollodata.com/scala-js-and-apollo-better-together-b066c6b09af4">blog posts</StyledA> to introduce new features to the developer community</li>
      </ul>
    ),
    url: "https://www.apollographql.com/careers/interns/2017/shadaj/"
  },
  {
    image: khanacademy,
    name: "Khan Academy",
    year: 2016,
    position: "Software Engineering Intern",
    description: (
      <ul>
        <li>Developed new student interfaces for graphing problems on mobile devices with React.js</li>
        <li>Implemented a mobile-first content editor with in-browser touch simulation used by all Khan Academy content creators</li>
        <li>Contributed new user interface components to a math expression keypad for mobile devices</li>
      </ul>
    ),
    url: "https://blog.khanacademy.org/interning-at-khan-academy-from-student-to-intern"
  },
  {
    image: payalabs,
    name: "Paya Labs",
    year: 2015,
    position: "Software Engineering Intern",
    description: (
      <ul>
        <li>Developed an online learning platform with Scala.js and Spray</li>
        <li>Redesigned website with Material Design principles</li>
        <li>Worked on frontend for student and teacher experiences with Scala.js</li>
        <li>Explored student-teacher interaction through WebRTC</li>
        <li>Built custom Docker images for the Drone CI system</li>
      </ul>
    ),
    url: "https://github.com/payalabs/scalajs-react-mdl"
  },
  {
    image: coursera,
    name: "Coursera",
    year: 2014,
    position: "Software Engineering Intern",
    description: (
      <ul>
        <li>Rewrote the User APIs using Naptime, Coursera’s library for writing REST APIs</li>
        <li>Developed with Scala.js an internal API Explorer to quickly view documentation about Coursera’s REST APIs and make requests</li>
        <li>Built AutoSchema, an open-source library to generate JSON Schema from Scala types</li>
      </ul>
    ),
    url: "https://medium.com/coursera-engineering/my-internship-at-coursera-afbec2b2f44a"
  }
]

const linkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="75" viewBox="0 0 24 24" width="75" style={{
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  }}>
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/>
  </svg>
);

export default class Work extends React.Component {
  render() {
    return (
      <div>
        <Nav current="work"/>
        <Content>
          <PageTitle>I've worked at a few companies</PageTitle>
          <div style={{
            display: "flex",
            flexWrap: "wrap",
          }}>
            {works.map((work, i) => {
              return (
                <Card
                  right={i % 2 !== 0}
                  icon={linkIcon}
                  image={work.image}
                  imageSize={work.imageSize}
                  name={work.name}
                  title={work.year + " • " + work.position}
                  description={work.description} url={work.url}
                  key={i}
                />
              );
            })}
          </div>
        </Content>
      </div>
    )
  }
}
